import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public static readonly INITIAL_TIME = 20;
  alphabet: Array<Character> = [
    {char: '習', value: 0},
    {char: '不', value: 1},
    {char: '而', value: 2},
    {char: '有', value: 3},
    {char: '說', value: 4},
    {char: '時', value: 5},
    {char: '亦', value: 6},
    {char: '之', value: 7},
    {char: '朋', value: 8},
    {char: '乎', value: 9},
  ];
  game: Array<GameRow> = [];
  gameStarted: boolean;
  answer: Array<number> = [];
  correctAnswers: number;
  timeLeft: number = AppComponent.INITIAL_TIME;
  interval: any;

  newGame(): void {
    this.answer = [];
    this.timeLeft = AppComponent.INITIAL_TIME;
    this.game = this.shuffle();
    this.gameStarted = true;
    this.interval = setInterval(() => {
      this.timeLeft --;
      if (this.timeLeft === 0) {
        this.endGame();
      }
    }, 1000);
  }

  endGame(): void {
    clearInterval(this.interval);
    this.solve(this.game);
    this.correctAnswers = this.game.filter(value => value.userAnswer === value.correctAnswer).length;
    this.gameStarted = false;
    console.log(this.game);
  }

  shuffle(): Array<GameRow> {
    const result = [];

    this.alphabet.forEach(() => {
      const randomNumber = Math.floor(Math.random() * this.alphabet.length);
      result.push({symbol: this.alphabet[randomNumber]});
    });
    return result;
  }

  solve(array: Array<GameRow>): void {
    array.forEach(item => {
      item.correctAnswer = item.symbol.value;
    });
  }

  getQuota(): number {
    return (this.correctAnswers / this.game.length) * 100;
  }
}

export interface Character {
  char: string;
  value: number;
}

export interface GameRow {
  symbol: Character;
  correctAnswer: number;
  userAnswer: number;
}
